<template>
  <nav>
    <ul class="flex flex-wrap lg:flex-nowrap">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="index < items.length - 1 ? 'px-3 lg:px-5' : 'pl-3 lg:pl-5'"
        class="flex items-center justify-center w-full lg:w-auto"
      >
        <Link
          :data="item"
          @click.native.prevent
          @clicked="scrollToView"
          :class="index == 0 ? 'mt-8 lg:mt-0' : ''"
          class="text-2xl py-2 my-3 lg:py-0 lg:my-0 lg:mt-0 lg:text-lg font-bold text-gray-900 lg:hover:text-red-700 transition duration-300"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import Link from './Link'
import menu from '../assets/api/menu.json'

export default {
  name: 'Menu',
  data() {
    return {
      items: [],
    }
  },
  components: {
    Link,
  },
  methods: {
    scrollToView(el) {
      const url = new URL(el.href)
      const target = document.querySelector(url.hash)
      if (target) {
        history.replaceState({}, '', el.href)
        window.scrollTo(0, target.offsetTop - 104)
        this.$emit('navigate')
      }
    },
  },
  created() {
    this.items = menu
  },
}
</script>
