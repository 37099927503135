<template>
  <section class="bg-red-100 py-15 lg:py-20" :id="id">
    <div class="container">
      <h3 class="text-center font-bold text-red-700 text-3xl mb-10 lg:mb-20">
        {{ data.title }}
      </h3>
      <div class="block lg:flex justify-between">
        <div
          class="max-w-ms text-center mb-10 last:mb-0 lg:mb-0 lg:px-3"
          v-for="(block, index) in data.items"
          :key="index"
        >
          <img class="m-auto mb-7" :src="block.icon" />
          <h4
            class="text-center arsenal font-bold text-2xl max-w-xs m-auto mb-5"
          >
            {{ block.title }}
          </h4>
          <p class="text-center">{{ block.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '@/mixins/DynamicComponent'

export default {
  mixins: [DynamicComponent],
}
</script>
