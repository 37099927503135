<template>
  <section class="bg-red-100 pt-20" id="kontakti">
    <div class="container">
      <h3 class="text-3xl text-red-700 font-bold text-center mb-12">
        Контакты
      </h3>
      <div class="text-xl text-red-700 mb-7">
        <div v-if="phone" class="text-center mb-6">
          <Link :id="'phone'" :data="phone" />
        </div>
        <div class="text-center">
          <Link :id="'email'" :data="email" />
        </div>
      </div>
      <div class="social flex justify-center">
        <template v-for="(social, index) in settings.social">
          <a
            v-if="social.length"
            :key="index"
            class="rounded-full btn btn-white social-btn mx-2.5"
            :href="social"
            target="_blank"
          >
            <component :is="index"></component>
          </a>
        </template>
        <a
          v-if="canShare"
          class="rounded-full btn btn-white social-btn mx-2.5"
          href="#"
          target="_blank"
          @click.prevent="shareit"
        >
          <Share />
        </a>
      </div>
      <div class="copyright py-5 mt-20">
        <p class="text-gray-700">2022 © Все права защищены</p>
      </div>
    </div>
  </section>
</template>

<script>
import Link from './Link'
import { Facebook, Vk, Instagram, Telegram, Skype, Share } from './socials'

export default {
  props: {
    settings: {
      type: Object,
    },
  },
  components: {
    Link,
    Facebook,
    Vk,
    Instagram,
    Telegram,
    Skype,
    Share,
  },
  methods: {
    shareit() {
      navigator
        .share({
          title: 'Hello, I’m Anastasia.',
          text: 'A Proffesional English teacher.',
          url: window.location.href,
        })
        .then(function() {
          return console.log('Successful share')
        })
        .catch(function(e) {
          return console.log('Error sharing', e)
        })
    },
  },
  computed: {
    canShare() {
      return window.navigator.share
    },
    phone() {
      if (!this.settings.general.phone) {
        return false
      }
      return this.settings.general
        ? {
            href: `tel:${this.settings.general.phone}`,
            text: this.settings.general.phone,
            class: 'footer',
          }
        : {}
    },
    email() {
      return this.settings.general
        ? {
            href: `mailto:${this.settings.general.email}`,
            text: this.settings.general.email,
            class: 'footer',
          }
        : {}
    },
  },
}
</script>
