import Vue from 'vue'
import App from './App.vue'
import canUseWebP from './vendor/isWebpSupported'

import 'tailwindcss/tailwind.css'
import './assets/app.css'

Vue.config.productionTip = false
Vue.prototype.$custom = {
  menu: false,
}

Vue.filter('cloudinary', function(value, options) {
  if (!options) {
    return value
  }
  const out = ['c_fill']
  if (options.width) {
    var filename = value
    if (canUseWebP) {
      filename = value.substr(0, value.lastIndexOf('.')) + '.webp'
    }
    out.push(
      `w_${parseInt(
        options.width *
          (navigator.userAgent.includes('Google')
            ? 1
            : window.devicePixelRatio) -
          (document.body.offsetWidth < 1024 ? 40 : 0)
      )}`
    )
  }
  return filename.replace('upload/v', `upload/${out.join(',')}/v`)
})

// https://ik.imagekit.io/jsdev/hero_EHLDyu3FZ.jpg?tr=w-905,fo-fill

Vue.filter('imagekit', function(value, options) {
  if (!options) {
    return value
  }
  const out = ['fo-fill']
  if (options.width) {
    var filename = value
    out.push(
      `w-${parseInt(
        options.width *
          (navigator.userAgent.includes('Google')
            ? 1
            : window.devicePixelRatio) -
          (document.body.offsetWidth < 1024 ? 40 : 0)
      )}`
    )
  }
  return `${filename}?tr=${out.join(',')}`
})

new Vue({
  render: h => h(App),
}).$mount('#app')
