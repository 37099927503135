import app from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBZhEu47SUgMRGkWOxL7RULC9tBlHao_PM",
  authDomain: "englishpro-dev.firebaseapp.com",
  projectId: "englishpro-dev",
  storageBucket: "englishpro-dev.appspot.com",
  messagingSenderId: "159514084540",
  appId: "1:159514084540:web:5875d265227dabb11bbcb9",
  measurementId: "G-5ML2MYZCMY",
};

app.initializeApp(firebaseConfig);

const db = app.firestore();

export default app;
export { db };
