<template>
  <div class="fixed image-gallery">
    <div
      class="images flex"
      @click="closeGallery($event)"
      :style="{
        width: width,
        transform: `translate(${transformX}px, 0)`,
      }"
    >
      <div
        class="gallery-item flex justify-center items-center"
        v-for="(image, index) in images"
        :key="index"
      >
        <div class="inline-block relative">
          <img :src="image | fit" loading="lazy" />
          <button
            class="navigation-button absolute z-20 left-0 top-0 w-1/2 bottom-0 block"
            @click="slide(-1)"
          >
            <div></div>
          </button>
          <button
            class="navigation-button absolute z-20 right-0 top-0 w-1/2 bottom-0 block"
            @click="slide(+1)"
          >
            <div></div>
          </button>
        </div>
      </div>
    </div>
    <div class="absolute text-white text-center w-screen bottom-10">
      {{ active + 1 }} / {{ images.length }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
    }
  },
  computed: {
    width() {
      return this.images.length * document.body.clientWidth + 'px'
    },
    transformX() {
      return document.body.clientWidth * this.active * -1
    },
  },
  methods: {
    slide(i) {
      this.active =
        i < 0
          ? Math.max(0, this.active + i)
          : Math.min(this.active + i, this.images.length - 1)
    },
    closeGallery($event) {
      if ($event.target.nodeName !== 'BUTTON') {
        this.$emit('close')
      }
    },
  },
  filters: {
    fit(url) {
      const screen = {
        width: Math.min(document.body.clientWidth, window.innerWidth) - 100,
        height: Math.min(document.body.clientHeight, window.innerHeight) - 150,
      }
      return url.replace(
        /\/upload\/v/,
        `/upload/c_fit,w_${screen.width},h_${screen.height}/v`
      )
    },
  },
}
</script>
