<template>
  <div class="relative w-full h-hull">
    <textarea
      :name="name"
      placeholder=" "
      class="w-full h-full px-3 pt-4 pb-2
    border border-gray-100 focus:border-gray-900 transition duration-300
    rounded-lg invalid:border-red-700 ep-input"
    ></textarea>
    <label
      class="block w-full absolute pointer-events-none h-full flex items-start text-gray-900 top-0"
      ><span class="pl-3 pt-3">{{ label }}</span></label
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
}
</script>
