<template>
  <section class="bg-red-100" :id="id">
    <div class="container">
      <form
        ref="contact"
        class="px-4 py-10 lg:px-28 lg:py-24 bg-white contact-form"
        @submit.prevent="submit"
        novalidate
      >
        <h3 class="text-3xl text-center text-red-700 font-bold mb-12">
          {{ data.title }}
        </h3>
        <div class="block lg:flex">
          <div class="mb-5 lg:mb-0 lg:w-1/2 lg:pr-2.5">
            <Input
              name="name"
              label="Имя"
              required
              class="mb-5"
              @changed="validate"
              :class="{ invalid: !valid.name }"
            />
            <Input
              name="email"
              label="Эл. адрес"
              type="email"
              class="mb-5"
              :class="{ invalid: !valid.email }"
            />
            <Input
              name="phone"
              required
              label="Телефон"
              @changed="validate"
              type="phone"
              :class="{ invalid: !valid.phone }"
            />
          </div>
          <div class="lg:w-1/2 lg:pl-2.5 flex">
            <TextArea
              name="comment"
              label="Пожелания"
              :class="{ invalid: !valid.comment }"
            />
          </div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <Button
            :disabled="loading"
            class="btn-red mt-5 w-full h-12 lg:w-auto lg:mr-5 order-2 lg:order-1"
          >
            <span v-if="loading">
              <svg
                class="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
            <span v-if="!loading">Отправить</span>
          </Button>
          <div
            v-if="sent && !loading"
            class="mt-5 w-full bg-green p-2 flex rounded-lg items-center lg:order-2"
          >
            <img src="/images/check.svg" class="mr-2 ml-2" />
            <span
              >Thank you for contacting me. We will get back to you
              shortly.</span
            >
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Button from './Button'
import Input from './Input'
import TextArea from './TextArea'
import register from '../vendor/registration'

export default {
  props: {
    id: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      inputs: [],
      sent: false,
      loading: false,

      valid: {
        name: true,
        email: true,
        phone: true,
        comment: true,
      },
    }
  },
  mounted() {
    this.inputs = this.$refs['contact'].querySelectorAll('input, textarea')
  },
  methods: {
    async submit() {
      this.sent = false
      this.loading = true
      if (!this.validate()) {
        this.loading = false
        return false
      }
      let data = {}
      this.inputs.forEach(input => {
        data[input.name] = input.value
      })
      const status = await register(data)
      if (status.error) {
        this.loading = false
        return (this.valid[status.field] = false)
      }
      this.$refs['contact'].reset()
      this.sent = true
      this.loading = false
    },
    validate() {
      this.inputs.forEach(input => {
        this.valid[input.name] = input.validity.valid
      })
      return this.$refs['contact'].checkValidity()
    },
  },
  components: {
    Button,
    Input,
    TextArea,
  },
}
</script>
