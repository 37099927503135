<template>
  <section :class="data.classes" :id="id">
    <div class="container">
      <h3
        v-if="data.title"
        class="text-center text-red-700 font-bold text-3xl mb-15 lg:mb-20"
      >
        {{ data.title }}
      </h3>
      <div
        class="single-block"
        v-for="(block, index) in data.blocks"
        :key="index"
      >
        <ImageWithText :block="block" />
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '@/mixins/DynamicComponent'
import ImageWithText from './ImageWithText'

export default {
  mixins: [DynamicComponent],
  components: {
    ImageWithText,
  },
}
</script>
