<template>
 <p :id="id">{{data.msg}}</p>
</template>

<script>
import DynamicComponent from "../mixins/DynamicComponent"

export default {
  name: "Pharagraph",
  mixins: [DynamicComponent]
};
</script>
