<template>
  <section class="py-14 gallery text-white" :id="id">
    <div class="container text-center">
      <div class="max-w-xl m-auto">
        <h3 class="font-bold text-3xl mb-3">{{ data.title }}</h3>
        <p>{{ data.text }}</p>
        <Button
          @click="$emit('gallery', data.images)"
          v-if="data.images.length > 0"
          class="btn-white mt-4 block lg:inline-block w-full lg:w-auto"
          >Open Gallery</Button
        >
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '@/mixins/DynamicComponent'
import Button from './Button'

export default {
  mixins: [DynamicComponent],
  components: {
    Button,
  },
}
</script>
