<template>
  <section :id="id">
    <h3
      v-if="data.title"
      class="text-center text-red-700 font-bold text-3xl mb-20"
    >
      {{ data.title }}
    </h3>
    <div
      class="container flex py-15 lg:py-20 justify-between flex-col lg:flex-row"
    >
      <div
        v-for="(benefit, index) in data"
        :key="index"
        :class="classes(index)"
        class="lg:max-w-xs max-w-full flex items-center my-7 lg:mb-6 lg:mb-0"
      >
        <img :src="benefit.icon" class="lg:mb-6 mr-5" />
        <p class="text-xl lg:text-2xl">{{ benefit.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '../mixins/DynamicComponent'
export default {
  mixins: [DynamicComponent],
  methods: {
    classes(index) {
      if (index == 0) {
        return 'lg:pr-5 mt-0'
      }
      if (index == Object.keys(this.data).length - 1) {
        return 'lg:pl-5 mb-0'
      }

      return 'lgpx-5'
    },
  },
}
</script>
