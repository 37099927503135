const DynamicComponent = {
    props: {
        data: {},
        id: String
    },
    methods: {
        scrollToSection(id) {
            const target = document.querySelector(id)
            if (target) {
              history.replaceState({}, '', id)
              window.scrollTo(0, target.offsetTop - 104)
              this.$emit('navigate')
            }
        }
    }
}

export default DynamicComponent