<template>
  <section class="bg-red-900 text-white" :id="id">
    <div class="container py-15 lg:py-20">
      <h3
        v-if="data.title"
        class="text-center text-white font-bold text-3xl mb-16 lg:mb-20"
      >
        {{ data.title }}
      </h3>
      <div class="block lg:flex justify-between">
        <div
          v-for="(experience, index) in data.blocks"
          :key="index"
          :class="classes(index)"
          class="max-w-full lg:max-w-xs text-center mt-15 lg:mt-0"
        >
          <div class="text-7xl flex leading-none justify-center mb-3">
            <span>{{ experience.count }}</span>
            <span class="text-4xl ml-1">+</span>
          </div>
          <h4 class="text-xl font-bold mb-4 arsenal">{{ experience.title }}</h4>
          <div>{{ experience.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '@/mixins/DynamicComponent'

export default {
  mixins: [DynamicComponent],
  methods: {
    classes(index) {
      if (index == 0) {
        return 'lg:pr-5'
      }
      if (index == Object.keys(this.data).length - 1) {
        return 'lg:pl-5'
      }

      return 'lg:px-5'
    },
  },
}
</script>
