import firebase from './firebase'

const register = async function(data) {
  const db = firebase.firestore();
  const d = new Date();
  data.date = d.toISOString()
  return await db.collection("registrations").add(data);
}

export default register
