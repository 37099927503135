<template>
  <div id="app">
    <Header @menu="menu" ref="menu" :settings="settings" />
    <main class="content">
      <template v-for="(item, index) in components">
        <component
          @gallery="openGallery"
          :key="index"
          :is="item.component"
          :data="item.data"
          :id="item.id"
        ></component>
      </template>
    </main>
    <Footer :settings="settings" />
    <ImageGallery
      @close="closeGallery"
      v-if="gallery.length"
      :images="gallery"
    />
    <Menu
      @navigate="closeMenu"
      :class="{ active: showMenu }"
      class="fixed w-full left-0 top-15 z-10 bg-white h-screen mobile-menu lg:hidden"
    />
  </div>
</template>

<script>
import settings from './assets/api/settings.json'
import content from './assets/api/content.json'

import Header from './components/Header'
import Footer from './components/Footer'
import Menu from './components/Menu'

import ImageGallery from './components/ImageGallery'

import Hero from './components/Hero'
import Link from './components/Link'
import Pharagraph from './components/Pharagraph'
import Input from './components/Input'
import Button from './components/Button'
import Benefits from './components/Benefits'
import Experiences from './components/Experiences'
import About from './components/About'
import Gallery from './components/Gallery'
import Teaching from './components/Teaching'
import ContactForm from './components/ContactForm'

export default {
  name: 'App',
  data() {
    return {
      components: [],
      settings: {},
      gallery: [],
      showMenu: false,
    }
  },
  components: {
    Header,
    Footer,
    Pharagraph,
    Link,
    Hero,
    Input,
    Button,
    Benefits,
    Experiences,
    About,
    Gallery,
    Teaching,
    ContactForm,
    ImageGallery,
    Menu,
  },
  created() {
    this.settings = settings
    this.components = content
  },
  methods: {
    menu(show) {
      this.showMenu = show
    },
    copy(el) {
      const mail = el.innerHTML
      let input = document.createElement('input')
      input.value = mail
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      input.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      input.remove()
      // @todo show tooltip
      return false
    },
    openGallery(images) {
      this.gallery = images
    },
    closeGallery() {
      this.gallery = []
    },
    closeMenu() {
      this.showMenu = false
      this.$refs['menu'].active = false
    },
  },
}
</script>
