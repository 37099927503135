<template>
  <header class="flex header items-center fixed z-20 w-full">
    <div class="container flex justify-between items-center h-full">
      <div class="h-full flex items-center">
        <button
          @click="menu"
          :class="{ active: active }"
          class="h-full w-15 bg-red-700 mr-4 menu-bars relative lg:hidden"
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
        <a href="#" class="logo" title="English Pro">English Pro</a>
      </div>
      <div class="flex align-center h-full">
        <div class="flex lg:hidden h-full items-center">
          <Link
            v-if="phone"
            :id="'phone'"
            :data="phone"
            class="icon-link w-15 h-15 overflow-hidden icon-phone"
          />
          <div class="separator" v-if="false"></div>
          <Link
            :id="'email'"
            :data="email"
            class="icon-link w-15 h-15 overflow-hidden icon-email"
          />
        </div>
        <Menu class="h-auto hidden lg:flex" />
      </div>
    </div>
  </header>
</template>

<script>
import Link from './Link'
import Menu from './Menu'

export default {
  name: 'Header',
  props: {
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  components: {
    Link,
    Menu,
  },
  methods: {
    menu() {
      this.active = !this.active
      this.$emit('menu', this.active)
    },
  },
  computed: {
    phone() {
      if (!this.settings.general.phone) {
        return false
      }
      return this.settings.general
        ? {
            href: `tel:${this.settings.general.phone}`,
            text: this.settings.general.phone,
          }
        : {}
    },
    email() {
      return this.settings.general
        ? {
            href: `mailto:${this.settings.general.email}`,
            text: this.settings.general.email,
          }
        : {}
    },
  },
}
</script>
