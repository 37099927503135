<template>
  <section class="hero pt-14 lg:py-20" :id="id">
    <div class="container flex justify-between flex-col lg:flex-row">
      <div class="flex items-center ">
        <div class="w-full">
          <h1 class="text-red-700 text-7xl font-bold mb-4">{{ data.title }}</h1>
          <p class="text-gray-900 text-2xl mb-4">{{ data.subtitle }}</p>
          <Button @click="scrollToSection('#zapisatsa')" class="btn-red block w-full lg:inline-block lg:w-auto"
            >записаться</Button
          >
        </div>
      </div>
      <div class="mt-10 lg:mt-0 lg:w-2/3 relative right-0">
        <img :src="data.image | imagekit({ width: size })" />
      </div>
    </div>
  </section>
</template>

<script>
import DynamicComponent from '@/mixins/DynamicComponent'
import Button from './Button'

export default {
  mixins: [DynamicComponent],
  computed: {
    size() {
      if (document.body.offsetWidth < 1024) {
        return document.body.offsetWidth
      }
      return parseInt(Math.min(document.body.offsetWidth / 712, 1) * 712)
    },
  },
  components: {
    Button,
  },
}
</script>
