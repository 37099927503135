<template>
  <div class="flex flex-col lg:flex-row" :class="classes">
    <h2
      v-if="block.title"
      class="font-bold text-red-700 text-3xl mb-10 block lg:hidden"
    >
      {{ block.title }}
    </h2>
    <div class="image w-full lg:mr-5 lg:max-w-lg">
      <img :src="block.image | cloudinary({ width: size })" loading="lazy" />
    </div>
    <div class="flex flex-col w-full lg:ml-5 lg:max-w-lg">
      <h2
        v-if="block.title"
        class="font-bold text-red-700 text-3xl mb-5 hidden lg:block"
      >
        {{ block.title }}
      </h2>
      <h3
        v-if="block.subtitle"
        class="arsenal text-gray-900 text-2xl font-bold mb-6 mt-10 lg:mt-0"
      >
        {{ block.subtitle }}
      </h3>
      <div class="text-content" v-html="block.content"></div>
      <div v-if="block.button" class="mt-5">
        <Button @click="scrollToSection('#podhod')" class="btn-red w-full lg:w-auto">{{
          block.button.text
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button'
import DynamicComponent from '../mixins/DynamicComponent'
export default {
  mixins: [
    DynamicComponent
  ],
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    classes() {
      const blockClasses = { justify: 'justify-between', items: 'items-center' }
      if (this.block.classes) {
        let classesArray = this.block.classes.split(' ')
        classesArray.map(cls => {
          const key = cls.split('-')[0]
          blockClasses[key] = cls
        })
      }
      return Object.values(blockClasses).join(' ')
    },
    size() {
      if (document.body.offsetWidth < 1024) {
        return document.body.offsetWidth
      }
      return parseInt(Math.min(document.body.offsetWidth / 448, 1) * 448)
    },
  },
  components: {
    Button,
  },
}
</script>
