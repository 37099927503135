<template>
  <div class="relative w-full">
    <input
      :name="name"
      :required="required"
      placeholder=" "
      :type="type"
      class="w-full h-full px-3 pt-6 pb-2
    border border-gray-100 focus:border-gray-900 transition duration-300
    rounded-lg ep-input"
      @change="$emit('changed')"
    />
    <label
      class="block w-full absolute pointer-events-none h-full flex items-center text-gray-900 top-0"
      ><span class="pl-3">{{ label }}</span></label
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  watch: {
    valid() {},
  },
}
</script>
