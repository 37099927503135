<template>
  <a
    @click="$emit('clicked', $el)"
    :id="id"
    :href="data.href"
    :class="data.class"
    >{{ data.text }}</a
  >
</template>

<script>
import DynamicComponent from '../mixins/DynamicComponent'
export default {
  name: 'Link',
  mixins: [DynamicComponent],
}
</script>
